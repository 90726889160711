@import "../helpers/variables";

.br-btn,
.cadastro-usuario .box-table .modal-cadastro-btn-cadastrar,
.cadastro-usuario .box-table .br-btn-filtro {
  // border-radius: 0 !important;
  height: 36px !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  color: #fff !important;
  box-shadow: none !important;
  background-color: $colorPrimary !important;

  &-cancelar {
    background-color: transparent !important;
    border: 1px solid $colorPrimary !important;
    color: $colorPrimary !important;

    &-popup {
      &:hover {
        background-color: $colorPrimary !important;
        color: #fff !important;
      }
    }

    &:hover {
      background-color: $colorPrimary !important;
      color: #fff !important;
    }
  }

  &-activate {
    box-shadow: transparent !important;
    color: $colorPrimary !important;
    border: 1px solid $colorPrimary !important;
    box-shadow: none !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    background-color: transparent !important;
    @media (min-width: 600px) {
      min-width: 120px !important;
    }
    &-active {
      border: 1px solid $colorSecondary !important;
      background-color: $colorSecondary !important;
      box-shadow: transparent !important;
      font-size: 14px !important;
      font-weight: 400 !important;
      color: white !important;
      @media (min-width: 600px) {
        min-width: 120px !important;
      }
    }
  }

  @media (min-width: 600px) {
    min-width: 120px !important;
  }
}
