@import 'helpers/fonts';

// variaveis
@import "helpers/variables.scss";

// mixins
@import "helpers/mixins";

// functions
@import "helpers/functions";

// placeholders
@import "helpers/placeholders";


@import 'components/cadastro';
@import 'components/btn';

.container-gestao {
  min-height: 100vh;
  // background-color: #f6f6f6;

  .titulo {
    // margin-top: 10px;
    font-size: 16px;
    text-transform: uppercase;
    color: $colorPrimary;
  }
}

.breadcrumbs {
  // background-color: #e9edf2;
  padding: 0 0 10px !important;

  li {
    font-size: 8px;
    color: rgba(0, 0, 0, 0.5);
  }

  a {
    color: rgba(0, 0, 0, 0.5);
  }

  li:nth-child(even) {
    padding: 0 2px !important;
  }
}

.wrapper {
  min-height: calc(100vh - 86px);
  background-color: $colorWrapper;
  // background-color: #e4e4e4;
}


.v-btn.v-size--default {
  font-size: 0.8rem;
  font-weight: 600;
}

.container {
  padding: 8px 12px 8px 12px;
}

// .theme--dark {
//   opacity: 10;
// }

.v-toolbar__content, .v-toolbar__extension {
  padding: 4px 24px;
}

.theme--light.v-skeleton-loader .v-skeleton-loader__actions, .theme--light.v-skeleton-loader .v-skeleton-loader__article, .theme--light.v-skeleton-loader .v-skeleton-loader__card-heading, .theme--light.v-skeleton-loader .v-skeleton-loader__card-text, .theme--light.v-skeleton-loader .v-skeleton-loader__date-picker, .theme--light.v-skeleton-loader .v-skeleton-loader__list-item, .theme--light.v-skeleton-loader .v-skeleton-loader__list-item-avatar, .theme--light.v-skeleton-loader .v-skeleton-loader__list-item-text, .theme--light.v-skeleton-loader .v-skeleton-loader__list-item-two-line, .theme--light.v-skeleton-loader .v-skeleton-loader__list-item-avatar-two-line, .theme--light.v-skeleton-loader .v-skeleton-loader__list-item-three-line, .theme--light.v-skeleton-loader .v-skeleton-loader__list-item-avatar-three-line, .theme--light.v-skeleton-loader .v-skeleton-loader__table-heading, .theme--light.v-skeleton-loader .v-skeleton-loader__table-thead, .theme--light.v-skeleton-loader .v-skeleton-loader__table-tbody, .theme--light.v-skeleton-loader .v-skeleton-loader__table-tfoot {
  background: $colorWrapper !important;
}

.v-snack__content {
  align-items: center;
  display: flex;
  justify-content: space-between;
}


@media print {
  .no-print {
    display: none !important;
  }
}